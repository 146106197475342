.link {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(245, 245, 245, 1);
  text-decoration: none;
}

.link:hover {
  background-color: rgba(0, 161, 231, 0.2);
}
.link_s {
  font-family: "Zen Maru Gothic";
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: rgba(230, 225, 229, 1);
  text-decoration: none;
}
