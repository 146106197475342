.link {
  text-decoration: none;
  color: #000000;
  padding: 10px 10px;
  margin: 0 28px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
}

.link:hover {
  text-decoration: underline;
}

.link_active {
  font-weight: 400;
  margin: 0 28px;
  background-color: rgba(214, 243, 255, 1);
  text-decoration: none;
  color: #000000;
  padding: 10px 14px;
  border-radius: 33px;
  font-family: "Inter";
}
.link_active:hover {
  cursor: default;
}