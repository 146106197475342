@font-face {
  font-family: "Zen Maru Gothic";
  src: url(../public/fonts/ZenMaruGothic-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Zen Maru Gothic";
  src: url(../public/fonts/ZenMaruGothic-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Zen Maru Gothic";
  src: url(../public/fonts/ZenMaruGothic-Bold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Zen Maru Gothic";
  src: url(../public/fonts/ZenMaruGothic-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(../public/fonts/Nunito-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(../public/fonts/Nunito-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(../public/fonts/Nunito-Bold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(../public/fonts/Nunito-BoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(../public/fonts/Nunito-ExtraBold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(../public/fonts/Nunito-ExtraBoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(../public/fonts/Nunito-ExtraLight.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(../public/fonts/Nunito-ExtraLightItalic.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(../public/fonts/Nunito-Medium.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(../public/fonts/Nunito-MediumItalic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(../public/fonts/Nunito-SemiBold.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-variant: normal;
}

@font-face {
  font-family: "Nunito";
  src: url(../public/fonts/Nunito-SemiBoldItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  font-variant: normal;
}


